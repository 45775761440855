var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: "flex-1 d-flex ".concat(_vm.isMobileView ? '' : 'my-50')
  }, [_vm.type === 'inline' ? _c('HStack', {
    staticClass: "font-weight-bolder gap-2",
    class: {
      'flex-wrap': _vm.isMobileView
    }
  }, [_c('IAmLogoAirline', {
    attrs: {
      "airline": _vm.airline,
      "height": "25"
    }
  }), _c('span', {
    staticClass: "text-danger fw-800"
  }, [_vm._v(_vm._s(_vm.flightNumber))]), _c('div', [_vm._v(_vm._s(_vm.departure))]), _c('div', [_vm._v(_vm._s(_vm.arrival))]), _c('div', {
    staticClass: "text-info fw-800"
  }, [_vm._v(" " + _vm._s(_vm.time) + " ")]), _c('div', [_vm._v(_vm._s(_vm.date))])], 1) : _vm.type === 'block' ? _c('div', [_c('div', {
    staticClass: "d-flex-between"
  }, [_c('div', [_vm._v("Ngày " + _vm._s(_vm.dateType === 'departure' ? 'đi' : 'về') + ": "), _c('span', {
    staticClass: "text-info fw-700"
  }, [_vm._v(_vm._s(_vm.date))])]), _c('div', {
    staticClass: "text-danger fw-900"
  }, [_vm._v(" " + _vm._s(_vm.flightNumber) + " ")])]), _c('div', {
    staticClass: "d-flex-center gap-1 fw-600"
  }, [_c('div', {
    staticClass: "d-flex-center flex-column"
  }, [_c('div', [_vm._v(_vm._s(_vm.departureTime))]), _c('div', [_vm._v(_vm._s(_vm.departure))])]), _c('span', {
    staticClass: "d-flex-center flex-column mx-50"
  }, [_c('svg', {
    attrs: {
      "width": '100%',
      "height": "19",
      "viewBox": "0 0 428 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "5",
      "cy": "9.7085",
      "r": "5",
      "fill": "black"
    }
  }), _c('rect', {
    attrs: {
      "x": "10",
      "y": "9.2085",
      "width": "400",
      "height": "1",
      "fill": "#999999"
    }
  }), _c('path', {
    attrs: {
      "d": "M417.56 11.5085H412.52L410.9 14.2085H410V5.2085H410.9L412.52 7.9085H417.56L415.4 0.708496H417.2L421.52 7.9085H426.2C426.677 7.9085 427.135 8.09814 427.473 8.4357C427.81 8.77327 428 9.23111 428 9.7085C428 10.1859 427.81 10.6437 427.473 10.9813C427.135 11.3189 426.677 11.5085 426.2 11.5085H421.52L417.2 18.7085H415.4L417.56 11.5085Z",
      "fill": "black"
    }
  })]), _c('IAmLogoAirline', {
    attrs: {
      "airline": _vm.airline,
      "height": _vm.isMobileView ? '20' : '25'
    }
  })], 1), _c('div', {
    staticClass: "d-flex-center flex-column"
  }, [_c('div', [_vm._v(_vm._s(_vm.arrivalTime))]), _c('div', [_vm._v(_vm._s(_vm.arrival))])])])]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }