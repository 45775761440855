<template lang="">
  <div :class="`flex-1 d-flex ${isMobileView ? '' : 'my-50'}`">
    <HStack
      v-if="type==='inline'"
      class="font-weight-bolder gap-2"
      :class="{'flex-wrap': isMobileView}"
    >
      <IAmLogoAirline
        :airline="airline"
        height="25"
      />
      <span class="text-danger fw-800">{{ flightNumber }}</span>
      <div>{{ departure }}</div>
      <div>{{ arrival }}</div>
      <div class="text-info fw-800">
        {{ time }}
      </div>
      <div>{{ date }}</div>
    </HStack>
    <div v-else-if="type==='block'">
      <div class="d-flex-between">
        <div>Ngày {{ dateType === 'departure' ? 'đi' : 'về' }}: <span class="text-info fw-700">{{ date }}</span></div>
        <div class="text-danger fw-900">
          {{ flightNumber }}
        </div>
      </div>
      <div class="d-flex-center gap-1 fw-600">
        <div class="d-flex-center flex-column">
          <div>{{ departureTime }}</div>
          <div>{{ departure }}</div>
        </div>
        <span class="d-flex-center flex-column mx-50">
          <svg
            :width="'100%'"
            height="19"
            viewBox="0 0 428 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="5"
              cy="9.7085"
              r="5"
              fill="black"
            />
            <rect
              x="10"
              y="9.2085"
              width="400"
              height="1"
              fill="#999999"
            />
            <path
              d="M417.56 11.5085H412.52L410.9 14.2085H410V5.2085H410.9L412.52 7.9085H417.56L415.4 0.708496H417.2L421.52 7.9085H426.2C426.677 7.9085 427.135 8.09814 427.473 8.4357C427.81 8.77327 428 9.23111 428 9.7085C428 10.1859 427.81 10.6437 427.473 10.9813C427.135 11.3189 426.677 11.5085 426.2 11.5085H421.52L417.2 18.7085H415.4L417.56 11.5085Z"
              fill="black"
            />
          </svg>
          <IAmLogoAirline
            :airline="airline"
            :height="isMobileView ? '20' : '25'"
          />
        </span>
        <div class="d-flex-center flex-column">
          <div>{{ arrivalTime }}</div>
          <div>{{ arrival }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/composition-api'

export default {
  components: {
    IAmLogoAirline: () => import('@/components/IAmLogoAirline.vue'),
  },
  props: {
    type: {
      type: String,
      default: 'inline', // inline: dạng 1 dòng | block: dạng 2 khối between, có gạch nối
    },
    dateType: {
      type: String,
      default: 'departure',
    },
    segment: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const airline = computed(() => props.segment?.airline)
    const flightNumber = computed(() => `${props.segment?.airline}${props.segment?.flightNumber}`)
    const departure = computed(() => props.segment?.departure)
    const arrival = computed(() => props.segment?.destination)
    const time = computed(() => `${props.segment?.departureTime} - ${props.segment?.arrivalTime}`)
    const departureTime = computed(() => props.segment?.departureTime)
    const arrivalTime = computed(() => props.segment?.arrivalTime)
    const date = computed(() => props.segment?.departureDate)

    return {
      airline,
      flightNumber,
      departure,
      arrival,
      time,
      departureTime,
      arrivalTime,
      date,
    }
  },
}
</script>
<style lang="">

</style>
